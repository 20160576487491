@font-face {
	font-family: "Omnes Regular";
	src: url("/assets/omnes_regular.woff");
}

body {
	background-color: #262626;
	font-family: "Omnes Regular";
}

.btn-more {
	background-color: #413d3c;
	color: #afd43d;
}
.btn-more:hover {
	color: #FFFFFF;
}

section {
	padding-bottom: 50px;
	padding-top: 50px;
}

.multiselect-dropdown .dropdown-btn {
	color: white;
}

.selected-item {
	background: #afd43d !important;
	border: 2px solid #afd43d !important;
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
}

.multiselect-item-checkbox input + div:before {
	/* background: #afd43d !important; */
	border: 2px solid #afd43d !important;
}

.multiselect-item-checkbox input:checked + div:before {
	background: #afd43d !important;
	border: 2px solid #afd43d !important;
}

share-buttons {
	text-align: center !important;
}

.sb-group button {
	background-color: var(--button-color) !important;
	border-radius: 51% !important;
	height: 50px !important;
	width: 50px !important;
}

.menu-user {
	position: relative;
	height: 48px;
	width: 48px;
	display: block;
	display: flex;
	align-items: center;
	justify-content: center;
}
.col-md-auto .menu-user .text {
	display: none;
	height: 48px;
	width: 150px;
}

.menu-user:hover .text {
	display: block;
	position: absolute;
	top: 0;
	left: 48px;
	background: #f8f9fa;
	display: flex;
	align-items: center;
	padding: 0 10px;
	z-index: 1;
}
.active .menu-user:hover .text {
	background: #afd43d;
	color: #fff;
	z-index: 1;
}
